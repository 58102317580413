"use strict";

import axios from "axios";
var baseControllerAddress = 'gateIO/';

export default {
    post: (action, vm) => {
        return axios.post('/api/' + baseControllerAddress + action, vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                if (error.response.status != 401) {
                    alert(error);
                }
            });
    },
    getGateIOSelects: () => {
        return axios.get('/api/' + baseControllerAddress + 'getGateIOSelects')
            .then(result => {
                return result.data;
            }).catch(function (error) {
                if (error.response.status != 401) {
                    alert(error);
                }
            });
    },
    getModelList: (vm) => {
        return axios.post('/api/' + baseControllerAddress + 'filterModelList', vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                if (error.response.status != 401) {
                    alert(error);
                }
            });
    },
    getAll: () => {
        return axios.get('/api/' + baseControllerAddress + "getAll")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                if (error.response.status != 401) {
                    alert(error);
                }
            });
    },
    getById: (action, id) => {
        return axios.get('/api/' + baseControllerAddress + action + "/" + id)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                if (error.response.status != 401) {
                    alert(error);
                }
            });
    },
    deleteModel: (id) => {
        return axios.delete('/api/' + baseControllerAddress + "deleteModel/" + id)
            .catch(function (error) {
                alert(error);
            });
    },
    updateModel: (vm) => {
        return axios.put('/api/' + baseControllerAddress + "Update", vm)
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    newModel: () => {
        return axios.get('/api/' + baseControllerAddress + "NewModel")
            .then(result => {
                return result.data;
            }).catch(function (error) {
                alert(error);
            });
    },
    createModel: (vm) => {
        return axios.post('/api/' + baseControllerAddress + "create/", vm)
            .catch(function (error) {
                alert(error);
            });
    },
}